import React from 'react';

interface OptionallyClickableProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (e?: React.SyntheticEvent) => void;
}

function OptionallyClickable(props: OptionallyClickableProps) {
  const { children, className, onClick } = props;

  if (onClick) {
    return (
      <button className={className} onClick={onClick}>
        {children}
      </button>
    );
  } else {
    return <>{children}</>;
  }
}

export default OptionallyClickable;
