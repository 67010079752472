import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { hot } from 'react-hot-loader/root';
import { useLazyQuery } from '@apollo/react-hooks';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Collapsible from '../../library/collapsible/collapsible';
import DynamicMap from '../../library/dynamic-map/dynamic-map';
import Pagination from '../../library/pagination/pagination';
import Message from '../../components/message/message';
import PaginationMessage from './components/pagination-message/pagination-message';
import PitpassFilter from './components/pitpass-filter/pitpass-filter';
import StoreCountsByLocation from './components/store-counts-by-location/store-counts-by-location';
import FindStore from '../find-store/find-store';
import StoreLocatorMessage from '../store-locator-message/store-locator-message';
import StoreQuickView from '../store-quick-view/store-quick-view';
import StoreQuickViewList, {
  LIST_TYPE
} from '../store-quick-view-list/store-quick-view-list';

import {
  GET_STATE_STORE_LIST,
  GET_STORES_BY_CITY_STATE,
  GET_STORES_BY_LAT_LONG,
  GET_STORES_BY_SEARCH_TERM
} from './graphql/store-locator-queries';

import { useUserLocation } from '../../shared/hooks/hooks';

import { trackAction } from '../../shared/utils/analytics/analytics';
import {
  getStoreDataLayerValues,
  getStoreDetailsUrl,
  getStoreHours
} from '../../shared/utils/store/store';
import { isArrayWithLength } from '../../shared/utils/validators/validators';
import { getRadiusExpansionInfo } from './utils/store-locator-utils';

import { SEARCH_RADIUS_OPTIONS } from '../../shared/constants/search-types';
import {
  SEARCH_BY_CITY_STATE,
  SEARCH_BY_LAT_LONG,
  SEARCH_BY_TERM
} from './constants/store-locator-constants';

import './store-locator.scss';

const INITIAL_SEARCH_RADIUS = SEARCH_RADIUS_OPTIONS[3].value;
const fetchPolicy = 'cache-and-network';

function StoreLocator(props) {
  const {
    myStore,
    onSubmit,
    onSelectStore,
    selectStoreButtonLabel,
    shouldSetStoreAndCart,
    shouldSummarizePackage,
    defaultSearchTerm = '',
    showAppointmentCTA,
    showContinueCTA,
    showMakeMyStoreCTA,
    showShopProductsCTA,
    hideReadReviews,
    hideStoreDetailsLink = false,
    myStoreTitle,
    onShareClick,
    onContinueClick
  } = props;
  const [centerPos, setCenterPos] = useState(null);
  const [selectedStoreIdx, setSelectedStoreIdx] = useState(0);
  const [radius, setRadius] = useState(INITIAL_SEARCH_RADIUS);
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm);
  const [showLocationsByCity, setShowLocationsByCity] = useState(false);
  const [shouldCheckForRadiusExpansion, setShouldCheckForRadiusExpansion] =
    useState(false);
  const [radiusExpandedMsg, setRadiusExpandedMsg] = useState(false);
  const [latestSearch, setLatestSearch] = useState('');
  const [latestSearchType, setLatestSearchType] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [showMapView, setShowMapView] = useState(true);
  const [currentSearchedState, setCurrentSearchedState] = useState('');
  const [storeLocatorDataLayerValues, setStoreLocatorDataLayerValues] =
    useState(null);
  const [shouldFilterByPitPass, setShouldFilterByPitPass] = useState(false);

  const { userLocation, isDenied } = useUserLocation();

  const [
    searchStoresByTerm,
    {
      loading: storesBySearchLoading,
      data: storesBySearchTermData,
      error: storesBySearchTermError
    }
  ] = useLazyQuery(GET_STORES_BY_SEARCH_TERM, {
    fetchPolicy
  });

  const [getStoreStateList, { data: stateData, error: stateError }] =
    useLazyQuery(GET_STATE_STORE_LIST, {
      fetchPolicy
    });

  const [
    searchStoresByCityState,
    { data: storesByCityStateData, error: storesByCityStateError }
  ] = useLazyQuery(GET_STORES_BY_CITY_STATE, {
    fetchPolicy,
    variables: {
      userStoreCode: myStore?.code || ''
    }
  });

  const [
    searchStoresByLatLong,
    { data: storesByLatLongData, error: storesByLatLongError }
  ] = useLazyQuery(GET_STORES_BY_LAT_LONG, {
    fetchPolicy,
    variables: {
      userStoreCode: myStore?.code
    }
  });

  const storesByLatLong = storesByLatLongData?.store?.listByLatLong;
  const storeLocations = stateData?.store?.countByLocation || [];
  const {
    results: stores,
    pagination,
    sourceLatitude,
    sourceLongitude,
    hasPitPassStore
  } = searchResults || {};
  const myStoreCode = myStore?.code;
  const myStoreLat = myStore?.geoPoint?.latitude;
  const myStoreLong = myStore?.geoPoint?.longitude;
  const isSearchByCity =
    (Array.isArray(stores) && stores.length > 0) || currentSearchedState;
  const mapMarkers = useMemo(() => {
    if (!Array.isArray(stores)) {
      return [];
    }
    return stores.map((store, idx) => {
      const { pitPass, geoPoint } = store || {};
      const { latitude, longitude } = geoPoint || {};
      const page = pagination?.currentPage || 0;
      const storeIdx = idx + 1;
      const markerNumber = page > 0 ? page * 10 + storeIdx : storeIdx;
      const isActive = selectedStoreIdx === storeIdx;

      return {
        isActive,
        latitude,
        longitude,
        markerNumber,
        pitPass
      };
    });
  }, [pagination, selectedStoreIdx, stores]);

  const handleInitialLoadTimer = useRef(null);
  const storeListRef = useRef({});
  const myStoreRef = useRef(null);
  const prevMyStoreCodeRef = useRef(myStore?.code);

  useEffect(() => {
    if (sourceLatitude && !centerPos) {
      setCenterPos({
        latitude: sourceLatitude || 33.6805,
        longitude: sourceLongitude || -111.9771
      });
    }
  }, [centerPos, sourceLatitude, sourceLongitude]);

  const appendStoreFilter = useCallback(
    variables => {
      const modifiedVariables = { ...variables };

      if (shouldFilterByPitPass) {
        modifiedVariables.additionalFilters = {
          key: 'PIT_PASS',
          value: 'true'
        };
      }

      return modifiedVariables;
    },
    [shouldFilterByPitPass]
  );

  const searchStoresBy = useCallback(
    (name, variables) => {
      switch (name) {
        case SEARCH_BY_CITY_STATE: {
          searchStoresByCityState({ variables: appendStoreFilter(variables) });
          break;
        }
        case SEARCH_BY_LAT_LONG: {
          searchStoresByLatLong({ variables: appendStoreFilter(variables) });
          break;
        }
        case SEARCH_BY_TERM: {
          searchStoresByTerm({ variables: appendStoreFilter(variables) });
          break;
        }
      }

      setLatestSearchType(name);
    },
    [
      appendStoreFilter,
      searchStoresByCityState,
      searchStoresByLatLong,
      searchStoresByTerm
    ]
  );

  function onSearchTermChange(e) {
    const { target } = e;
    let searchTerm = target ? target.value : '';

    setLatestSearch('');
    setShouldFilterByPitPass(false);
    setSearchTerm(searchTerm);
  }

  function onRadiusChange(selectedOption) {
    if (selectedOption?.value) {
      setRadius(selectedOption.value);
    }
  }

  const scrollToStore = useCallback(
    storeIdx => {
      if (storeIdx === 'myStore') {
        // setTimeout here ensures focus has a chance to clear from other actions.
        setTimeout(() => {
          myStoreRef?.current?.scrollIntoView?.({
            behavior: 'smooth',
            // nearest is needed to avoid scrolling the window.
            block: 'nearest'
          });
        }, 100);
      } else if (typeof storeIdx === 'number') {
        // setTimeout here ensures focus has a chance to clear from other actions.
        setTimeout(() => {
          storeListRef?.current[storeIdx - 1]?.scrollIntoView?.({
            behavior: 'smooth',
            // 'nearest' is needed to avoid scrolling the window for first and last list items.
            // 'center' can cause the list _AND_ the window to scroll.
            block:
              Object.keys(storeListRef?.current || {}).length === storeIdx ||
              (!myStore && storeIdx === 1)
                ? 'nearest'
                : 'center'
          });
        }, 100);
      }
      setSelectedStoreIdx(typeof storeIdx === 'number' ? storeIdx : 0);
    },
    [myStore]
  );

  const centerMapOnMarkerClick = useCallback(
    storeIdx => {
      if (storeIdx === 'myStore' && myStoreLat && myStoreLong) {
        setCenterPos({
          latitude: myStoreLat,
          longitude: myStoreLong
        });
      } else {
        const newSelectedStoreIdx = typeof storeIdx === 'number' ? storeIdx : 0;
        if (newSelectedStoreIdx > 0) {
          const selectedMarker = mapMarkers[newSelectedStoreIdx - 1];
          if (selectedMarker) {
            setCenterPos({
              latitude: selectedMarker.latitude,
              longitude: selectedMarker.longitude
            });
          }
        }
      }
    },
    [mapMarkers, myStoreLat, myStoreLong]
  );

  const onNearbyStoreClick = useCallback(
    e => {
      scrollToStore(e);
      centerMapOnMarkerClick(e);
    },
    [centerMapOnMarkerClick, scrollToStore]
  );

  const onMyStoreClick = useCallback(() => {
    scrollToStore('myStore');
    centerMapOnMarkerClick('myStore');
  }, [centerMapOnMarkerClick, scrollToStore]);

  function handleSearchByState(name) {
    getStoreStateList({
      variables: { state: name }
    });

    setCurrentSearchedState(name);
    setLatestSearch(name);
  }

  function handlePagination(page) {
    if (myStore) {
      searchStoresBy(SEARCH_BY_LAT_LONG, {
        latitude: searchResults?.sourceLatitude,
        longitude: searchResults?.sourceLongitude,
        page,
        radius
      });
    }

    scrollToStore(1);
  }

  function handleCollapsibleCitySearch(city) {
    if (city && currentSearchedState) {
      trackAction('store_locator_state_city', storeLocatorDataLayerValues);
      searchStoresBy(SEARCH_BY_CITY_STATE, {
        city,
        state: currentSearchedState
      });

      setLatestSearch(name);
      setShowMapView(true);
      setShowLocationsByCity(false);
      window.scrollTo(0, 0);
    }
  }

  function getStoreListNumber(page) {
    return page > 0 ? parseInt(page + '0') : page;
  }

  function togglePitPassFilter() {
    setShouldFilterByPitPass(
      prevShouldFilterByPitPass => !prevShouldFilterByPitPass
    );
  }

  useEffect(() => {
    function handleInitialPageLoad() {
      setHasInitialized(true);
      if (!myStore) {
        setShowMapView(false);
        getStoreStateList();
      } else {
        searchStoresBy(SEARCH_BY_TERM, {
          radius: INITIAL_SEARCH_RADIUS,
          userStoreCode: myStore?.code
        });
      }
    }

    handleInitialLoadTimer.current = setTimeout(handleInitialPageLoad, 5000);

    return () => {
      clearTimeout(handleInitialLoadTimer.current);
    };
  }, [getStoreStateList, myStore, myStoreLat, myStoreLong, searchStoresBy]);

  useEffect(() => {
    const searchStores = storesBySearchTermData?.store || {};
    if (
      searchStores?.search &&
      hasInitialized &&
      latestSearchType === SEARCH_BY_TERM
    ) {
      setSearchResults(searchStores.search);
    }
  }, [hasInitialized, latestSearchType, storesBySearchTermData?.store]);

  useEffect(() => {
    const searchStoresByCity = storesByCityStateData?.store?.searchByCity || {};
    if (
      (searchStoresByCity?.pagination || searchStoresByCity?.results) &&
      latestSearchType === SEARCH_BY_CITY_STATE
    ) {
      setSearchResults(searchStoresByCity);
    }
  }, [latestSearchType, storesByCityStateData?.store?.searchByCity]);

  useEffect(() => {
    if (
      (storesByLatLong?.pagination || storesByLatLong?.results) &&
      latestSearchType === SEARCH_BY_LAT_LONG
    ) {
      setSearchResults(storesByLatLong);
    }
  }, [latestSearchType, storesByLatLong]);

  useEffect(() => {
    if (stores && hasInitialized) {
      let firstNearbyStoreState = null;
      if (Array.isArray(stores) && stores.length > 0) {
        firstNearbyStoreState = stores[0]?.address?.region;
      }

      if (firstNearbyStoreState) {
        setCurrentSearchedState(firstNearbyStoreState?.name);
        getStoreStateList({
          variables: {
            state: showMapView ? firstNearbyStoreState?.name : ''
          }
        });
      } else {
        setCurrentSearchedState('');
        getStoreStateList();
      }
    }
  }, [stores, hasInitialized, getStoreStateList, showMapView]);

  useEffect(() => {
    if (myStore) {
      const variables = {
        radius: INITIAL_SEARCH_RADIUS,
        searchTerm: myStore?.address?.postalCode,
        userStoreCode: myStore?.code
      };

      searchStoresBy(SEARCH_BY_TERM, variables);
      setShowMapView(true);
      setHasInitialized(true);
      clearTimeout(handleInitialLoadTimer.current);

      if (
        prevMyStoreCodeRef.current &&
        prevMyStoreCodeRef.current !== myStore.code
      ) {
        prevMyStoreCodeRef.current = myStore.code;
        scrollToStore('myStore');
      }
    }
  }, [myStore, searchStoresBy, scrollToStore]);

  useEffect(() => {
    let variables, search;

    if (latestSearch) {
      search = SEARCH_BY_TERM;
      variables = {
        radius,
        searchTerm: latestSearch,
        userStoreCode: myStoreCode
      };
    } else {
      search = SEARCH_BY_LAT_LONG;

      if (myStoreLat && myStoreLong) {
        variables = {
          latitude: myStoreLat,
          longitude: myStoreLong,
          radius: INITIAL_SEARCH_RADIUS
        };
      } else if (!isDenied && userLocation) {
        variables = {
          ...userLocation,
          radius: INITIAL_SEARCH_RADIUS
        };
        setShowMapView(true);
      }
    }

    if (variables && search) {
      searchStoresBy(search, variables);
    }
  }, [
    isDenied,
    latestSearch,
    myStoreCode,
    myStoreLat,
    myStoreLong,
    radius,
    searchStoresBy,
    userLocation
  ]);

  useEffect(() => {
    setStoreLocatorDataLayerValues(getStoreDataLayerValues(myStore));
  }, [myStore]);

  useEffect(() => {
    if (
      latestSearchType === SEARCH_BY_TERM &&
      storesBySearchTermData &&
      shouldCheckForRadiusExpansion
    ) {
      setShouldCheckForRadiusExpansion(false);
      const { newRadius, msg } = getRadiusExpansionInfo(
        radius,
        storesBySearchTermData
      );
      setRadiusExpandedMsg(msg);
      setRadius(newRadius);
    }
  }, [
    latestSearchType,
    radius,
    shouldCheckForRadiusExpansion,
    storesBySearchTermData
  ]);

  if (
    (storesBySearchTermError ||
      storesByCityStateError ||
      storesByLatLongError ||
      stateError) &&
    !window.isPreRendering
  ) {
    throw (
      storesBySearchTermError ||
      storesByCityStateError ||
      storesByLatLongError ||
      stateError
    );
  }

  const storeQuickViews = stores?.map(store => ({
    additionalServices: store?.additionalServices,
    address: store?.address,
    distance: store?.distance,
    geoPoint: store?.geoPoint,
    legacyStoreCode: store?.legacyStoreCode,
    onChangeStore: onSubmit,
    pitPass: store?.pitPass,
    rating: store?.rating,
    shouldSummarizePackage: shouldSummarizePackage,
    showAltDirectionsCTA: true,
    showAltScheduleAppointmentCTA: true,
    showAltShareStoreCTA: true,
    showMakeMyStoreCTA: true,
    storeCode: store?.code,
    storeDetailsLink: hideStoreDetailsLink
      ? null
      : getStoreDetailsUrl(store?.address, store?.code),
    storeHours: getStoreHours()
  }));

  return (
    <div>
      <div styleName={showMapView ? 'container' : 'container-no-map'}>
        <div
          styleName={showMapView ? 'store-search search-grid' : 'store-search '}
        >
          <FindStore
            isSearching={storesBySearchLoading}
            onRadiusChange={onRadiusChange}
            onSearchTermChange={onSearchTermChange}
            onSubmit={e => {
              e.preventDefault();
              trackAction('store_locator_search', {
                store_search_radius: radius,
                store_search_term: searchTerm
              });

              setLatestSearch(searchTerm);
              setShouldFilterByPitPass(false);
              setShouldCheckForRadiusExpansion(true);
              setRadiusExpandedMsg(null);
              setShowMapView(true);
            }}
            radius={radius}
            searchTerm={searchTerm}
          />
        </div>
        {showMapView && (
          <>
            <div styleName="map">
              {!window.isPreRendering && (
                <DynamicMap
                  apiKey={process.env.__GOOGLE_API_KEY__}
                  centerPos={centerPos}
                  markers={Array.isArray(stores) && mapMarkers}
                  myStorePos={myStore?.geoPoint}
                  onMyStoreClick={onMyStoreClick}
                  onNearbyStoreClick={onNearbyStoreClick}
                  userPos={userLocation}
                />
              )}
            </div>
            <div styleName="store-list">
              {myStore && (
                <div
                  ref={myStoreRef}
                  styleName={
                    selectedStoreIdx === 0 ? 'my-store-selected' : 'my-store'
                  }
                >
                  <StoreQuickView
                    address={
                      window.isPreRendering ? undefined : myStore?.address
                    }
                    className="my-store"
                    distance={window.isPreRendering ? 0 : myStore?.distance}
                    geoPoint={
                      window.isPreRendering ? undefined : myStore?.geoPoint
                    }
                    hideReadReviews={hideStoreDetailsLink}
                    isCollapsible
                    isMyStore
                    isPitPassStore={myStore?.pitPass}
                    legacyStoreCode={myStore?.legacyStoreCode}
                    myStoreTitle={myStoreTitle}
                    onAddressClick={onMyStoreClick}
                    onCallStore={() =>
                      trackAction(
                        'store_locator_call_store',
                        storeLocatorDataLayerValues
                      )
                    }
                    onChangeStore={onSubmit}
                    onContinueClick={onContinueClick}
                    onDistanceClick={onMyStoreClick}
                    onGetDirectionsClick={() =>
                      trackAction(
                        'store_locator_directions',
                        storeLocatorDataLayerValues
                      )
                    }
                    onReadReviewClick={() =>
                      trackAction(
                        'store_locator_read_reviews',
                        storeLocatorDataLayerValues
                      )
                    }
                    onScheduleAppointmentClick={() =>
                      trackAction(
                        'store_locator_schedule_appointment',
                        storeLocatorDataLayerValues
                      )
                    }
                    onSelectStore={onSelectStore}
                    onSetStoreClick={() => {
                      trackAction(
                        'store_locator_make_this_my_store',
                        storeLocatorDataLayerValues
                      );
                    }}
                    onShareClick={onShareClick}
                    onShopProductsClick={() =>
                      trackAction(
                        'store_locator_shop_products',
                        storeLocatorDataLayerValues
                      )
                    }
                    onStoreDetailsLinkClick={() =>
                      trackAction(
                        'store_locator_details',
                        storeLocatorDataLayerValues
                      )
                    }
                    rating={window.isPreRendering ? 0 : myStore?.rating}
                    shouldSummarizePackage={shouldSummarizePackage}
                    showAltDirectionsCTA
                    showAltShareStoreCTA
                    showAppointmentCTA={showAppointmentCTA}
                    showContinueCTA={showContinueCTA}
                    showMakeMyStoreCTA={showMakeMyStoreCTA}
                    showShopProductsCTA={showShopProductsCTA}
                    storeCode={myStore?.code}
                    storeDetailsLink={
                      hideStoreDetailsLink
                        ? null
                        : getStoreDetailsUrl(myStore?.address, myStore?.code)
                    }
                    storeHours={getStoreHours()}
                  />
                </div>
              )}

              {stores?.length > 0 && (
                <>
                  {hasPitPassStore && (
                    <PitpassFilter
                      isEnabled={shouldFilterByPitPass}
                      onToggle={togglePitPassFilter}
                    />
                  )}

                  {!isEmpty(pagination) && !window.isPreRendering && (
                    <PaginationMessage
                      maxResultsPerPage={10}
                      pageIndex={pagination.currentPage}
                      styleName={
                        radiusExpandedMsg ? 'pagination-no-border' : undefined
                      }
                      totalNumberOfResults={pagination.totalNumberOfResults}
                    />
                  )}

                  {radiusExpandedMsg && (
                    <Message isWarning message={radiusExpandedMsg} />
                  )}

                  <StoreQuickViewList
                    className="store"
                    hideReadReviews={hideReadReviews}
                    hideStoreDetailsLink={hideStoreDetailsLink}
                    listNumberOffset={getStoreListNumber(
                      pagination?.currentPage || 0
                    )}
                    listType={LIST_TYPE.COLLAPSIBLE}
                    onCallStore={dataLayerValues =>
                      trackAction('store_locator_call_store', dataLayerValues)
                    }
                    onGetDirectionsClick={dataLayerValues =>
                      trackAction('store_locator_directions', dataLayerValues)
                    }
                    onNearbyStoreClick={onNearbyStoreClick}
                    onReadReviewClick={dataLayerValues =>
                      trackAction('store_locator_read_reviews', dataLayerValues)
                    }
                    onScheduleAppointmentClick={dataLayerValues =>
                      trackAction(
                        'store_locator_schedule_appointment',
                        dataLayerValues
                      )
                    }
                    onSelectStore={onSelectStore}
                    onSetStoreClick={dataLayerValues => {
                      trackAction(
                        'store_locator_make_this_my_store',
                        dataLayerValues
                      );
                      trackAction(
                        'store_locator_distance_between_stores',
                        dataLayerValues
                      );
                    }}
                    onShopProductsClick={dataLayerValues =>
                      trackAction(
                        'store_locator_shop_products',
                        dataLayerValues
                      )
                    }
                    onStoreDetailsLinkClick={dataLayerValues =>
                      trackAction(`store_locator_details`, dataLayerValues)
                    }
                    selectStoreButtonLabel={selectStoreButtonLabel}
                    selectedStoreIdx={selectedStoreIdx}
                    shouldSetStoreAndCart={shouldSetStoreAndCart}
                    showAdditionalServicesBanner
                    showAppointmentCTA={showAppointmentCTA}
                    showShopProductsCTA={showShopProductsCTA}
                    storeRef={storeListRef}
                    stores={window.isPreRendering ? [] : storeQuickViews}
                  />

                  {!window.isPreRendering &&
                    pagination?.totalNumberOfResults > 10 && (
                      <div styleName="pagination">
                        <Pagination
                          currentPage={pagination?.currentPage}
                          numberOfPages={pagination?.numberOfPages}
                          onPageChange={e => handlePagination(e)}
                        />
                      </div>
                    )}
                </>
              )}
              {!storesBySearchLoading && !isArrayWithLength(stores) && (
                <StoreLocatorMessage
                  isValidSearch={Boolean(stores)}
                  latestSearch={latestSearch}
                />
              )}
            </div>
          </>
        )}
      </div>

      <section styleName="locations">
        {showMapView && (
          <Collapsible
            collapseType="SECONDARY"
            isOpen={showLocationsByCity}
            label={`Tire and Wheel Stores Located by ${
              currentSearchedState ? 'City' : 'State'
            }`}
            onToggle={() => {
              setShowLocationsByCity(!showLocationsByCity);
            }}
          >
            <h4 styleName="locations-heading">
              {currentSearchedState} Tire and Wheel Stores
            </h4>
            <StoreCountsByLocation
              locations={storeLocations}
              onClick={
                isSearchByCity
                  ? e => handleCollapsibleCitySearch(e)
                  : e => handleSearchByState(e)
              }
            />
          </Collapsible>
        )}
        {!showMapView && (
          <>
            <h3 styleName="locations-title">Discount Tire Stores</h3>
            <h4 styleName="locations-heading">{`Stores Located by ${
              currentSearchedState ? 'City' : 'State'
            }`}</h4>
            <StoreCountsByLocation
              locations={storeLocations}
              onClick={
                isSearchByCity
                  ? e => handleCollapsibleCitySearch(e)
                  : e => handleSearchByState(e)
              }
            />
          </>
        )}
      </section>
    </div>
  );
}

StoreLocator.propTypes = {
  defaultSearchTerm: PropTypes.string,
  hasAppointmentStoreGeoPoint: PropTypes.bool,
  hideReadReviews: PropTypes.bool,
  hideStoreDetailsLink: PropTypes.bool,
  myStore: PropTypes.shape({
    address: PropTypes.shape({
      line1: PropTypes.string,
      postalCode: PropTypes.string,
      region: PropTypes.shape({
        isocodeShort: PropTypes.string
      }),
      town: PropTypes.string
    }),
    code: PropTypes.string,
    distance: PropTypes.number,
    geoPoint: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number
    }),
    legacyStoreCode: PropTypes.string,
    pitPass: PropTypes.bool,
    rating: PropTypes.shape({
      rating: PropTypes.number
    }),
    storeCode: PropTypes.string
  }).isRequired,
  myStoreTitle: PropTypes.string,
  onContinueClick: PropTypes.func,
  onSelectStore: PropTypes.bool,
  onShareClick: PropTypes.func,
  onSubmit: PropTypes.func,
  selectStoreButtonLabel: PropTypes.string,
  shouldSetStoreAndCart: PropTypes.bool,
  shouldSummarizePackage: PropTypes.bool,
  showAppointmentCTA: PropTypes.bool,
  showContinueCTA: PropTypes.bool,
  showMakeMyStoreCTA: PropTypes.bool,
  showShopProductsCTA: PropTypes.bool,
  showStoreAddressReviews: PropTypes.bool
};

export default hot(StoreLocator);
